import React, {  useContext } from "react";
import "./NavBar.css";
import logo from "../../assets/logo/Studio_Vador_logo.png";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase.config";
import { UserContext } from "../../context/userContext";

function NavBar() {

  const { currentUser } = useContext(UserContext);

  // LogOut

  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch {
      alert(
        "For some reasons we can't deconnect, please check your internet connexion and retry."
      );
    }
  };

  return (
    <>
      {currentUser && (
        <header>
          <nav className="menu">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Studio_Vador_logo" />
              </Link>
            </div>
            <ul className="dummy">
              <li className="link btn" onClick={logOut}>
                <p>Deconnexion</p>
              </li>
            </ul>
          </nav>
        </header>
      )}
    </>
  );
}

export default NavBar;
