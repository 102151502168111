import React, { useState, useRef } from "react";
import "./Modal.css";
import { AnimatePresence, motion } from "framer-motion";
import { addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase.config";

function Modal(props, { setItems }) {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const [itemType, setItemType] = useState("Location");

  const onOptionChange = (e) => {
    setItemType(e.target.value);
  };

  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };

  const [image, setImage] = useState(null);

  const handleImage = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const [isLoading, setIsLoading] = useState(false)
  const createItem = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    let newItem = {
      type: itemType,
      titre: inputs.current[0].value,
      image: "",
      imageName: image.name,
      prix: inputs.current[1].value,
      description: inputs.current[2].value.replace(/\n/g, '<br>'),
      date: new Date(),
    };
    const metadata = {
      contentType: "image/jpeg",
    };

    const storageRef = ref(storage, "images/" + image.name);
    const uploadTask = uploadBytesResumable(storageRef, image, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            console.log("Nothing happening");
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("image available at", downloadURL);
          newItem.image = downloadURL;
          try {
            addDoc(props.itemsCollectionRef, newItem);

            navigate("/");
          } catch (error) {
            console.log(error);
          }
        });
      }
    );
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <button className="button" onClick={toggleModal}>
        Ajouter
      </button>
      <AnimatePresence>
        {modal && (
          <div className="modal">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
              exit={{ opacity: 0, transition: { delay: 0.1 } }}
              className="overlay"
            ></motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1, transition: { duration: 0.3 } }}
              exit={{ scale: 0 }}
              className="modal-content"
            >
              <div className="button-close-modal" onClick={toggleModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#ffffff80"
                >
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                </svg>
              </div>
              <form className="info" onSubmit={createItem}>
                <h1 className="title">Nouvelle Vente/Location</h1>

                <div className="inputBox-Radio">
                  <span>Type :</span>
                  <div className="boxRadio">
                    {/* <div className="radioBox"> */}
                    <label htmlFor="color" className="radio">
                      <input
                        type="radio"
                        className="inputColorOne"
                        value="Location"
                        name="type"
                        checked={itemType === "Location"}
                        onChange={onOptionChange}
                      />
                      Location
                      <span></span>
                    </label>
                    <label htmlFor="color" className="radio">
                      <input
                        type="radio"
                        className="inputColorOne"
                        value="Vente"
                        name="type"
                        checked={itemType === "Vente"}
                        onChange={onOptionChange}
                      />
                      Vente
                      <span></span>
                    </label>
                  </div>
                </div>

                <div className="inputBox">
                  <input
                    ref={addInputs}
                    type="text"
                    name="titre"
                    id="titre"
                    required="required"
                  />
                  <span>Titre :</span>
                </div>

                <div className="inputBox">
                  <input
                    ref={addInputs}
                    type="number"
                    name="prix"
                    id="prix"
                    min="0"
                    required="required"
                  />
                  <span>Prix :</span>
                </div>
                <div className="inputBox">
                  <input
                    type="file"
                    name="image"
                    id="prix"
                    onChange={handleImage}
                  />
                  <span>Photos :</span>
                </div>
                <div className="inputBox">
                  <textarea
                    ref={addInputs}
                    type="text"
                    name="prix"
                    id="prix"
                    min="0"
                    required="required"
                  />
                  <span>Description :</span>
                </div>

                <div className="validation"></div>
                <div>
                  <button className="button" type="submit">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {
                      isLoading ? 
                      <div class="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div> :
                      <p>Register</p>
                    }
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Modal;
