import { Route, Routes } from "react-router";
import NavBar from "./components/NavBar/NavBar";
import Home from "./pages/Home";
import Private from "./pages/Private/Private";
import PrivateDashBoard from "./pages/Private/PrivateDashBoard/PrivateDashBoard";


function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Private" element={<Private />}>
          <Route path="/Private/PrivateDashBoard" element={<PrivateDashBoard />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
