import "./Home.css";
import "./Form.css";
import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo/Studio_vador_logo_fond_noir.png";
import { UserContext } from "../context/userContext";
import { Navigate } from "react-router-dom";

const Home = () => {
  const { SignInFunc } = useContext(UserContext);

  const navigate = useNavigate();

  const [validation, setValidation] = useState("");
  const [passWord, setPassWord] = useState('')

  const handlePass = (event) => {
    console.log(event.target.value)
    setPassWord(event.target.value);
  };

  
  const formRef = useRef();
  
  const handleForm = async (e) => {
    e.preventDefault();
    
    try {
      await SignInFunc(passWord);

      setValidation("");
      
      navigate("/Private/PrivateDashBoard");
    } catch (error) {
      setValidation("Password incorrect.");
    }
  };

  const { currentUser } = useContext(UserContext);

  if (currentUser) {
    return <Navigate to="/Private/PrivateDashBoard" />;
  }

  return (
    <div className="contact-card">
      <img src={logo} alt="" className="img" />
      <form ref={formRef} onSubmit={handleForm} className="info">
        <div className="inputBox">
          <input
            onChange={handlePass}
            value={passWord}
            type="password"
            name="pwd"
            id="signInPwd"
            required="required"
          />
          <span>Password :</span>
        </div>

        <div className="validation">{validation}</div>
        <div>
          <button className="button" type="submit">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Connexion
          </button>
        </div>
      </form>
    </div>
  );
};

export default Home;

