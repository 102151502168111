import React, { useState, useRef, useEffect } from "react";
import "./Modal.css";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { storage, db } from "../../firebase.config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

function EditModal(props) {
  const [item, setItem] = useState([]);
  const [itemDoc, setItemDoc] = useState({});

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const [itemType, setItemType] = useState("Location")
  const [oldItemType, setOldItemType] = useState("Location")

  const onOptionChange = e => {
    setItemType(e.target.value)
  }

  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);

    }
  };

  useEffect(() => {
    const getItem = async () => {
      const itemDoc = doc(db, "items", props.id);
      setItemDoc(itemDoc);
      const docSnap = await getDoc(itemDoc);
      setItem(docSnap.data());
      setItemType(item.type)
      setOldItemType(item.type)
      return item;
    };

    getItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [image, setImage] = useState(null);

  const handleImage = (e) => {
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };



  const [isLoading, setIsLoading] = useState(false)

  const updateUser = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    let newItem = {
      type: item.type,
      titre: item.titre,
      image: item.image,
      imageName: item.imageName,
      prix: item.prix,
      description: item.description,
    };
    // if sur chaque element savoir si il a ete modifier, boucle sur les photos
    if (itemType !== oldItemType) newItem.type = itemType;
    if (inputs.current[0].value) newItem.titre = inputs.current[0].value;
    if (inputs.current[1].value) newItem.prix = inputs.current[1].value;
    if (inputs.current[2].value) newItem.description = inputs.current[2].value.replace(/\n/g, '<br>');

    if(image !== null) {
      const metadata = {
        contentType: "image/jpeg",
      };
    const storageRef = ref(storage, "images/" + image.name);
    const uploadTask = uploadBytesResumable(storageRef, image, metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              console.log("Nothing happening")
            
          }
        }, 
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("image available at", downloadURL);
            newItem.image = downloadURL
            newItem.imageName = image.name
            if (window.confirm("T'es sûre que tu veux modifier ?") === true) {
              try {
                updateDoc(itemDoc, newItem);
                navigate('/')
              } catch (error) {
                console.log(error);
              }
            }
          });
        }
      );
    }


    if (window.confirm("T'es sûre que tu veux modifier ?") === true) {
      try {
        await updateDoc(itemDoc, newItem);
        navigate('/')
      } catch (error) {
        console.log(error);
      }
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <button className="button" onClick={toggleModal}>
        Update
      </button>
      <AnimatePresence>
        {modal && (
          <div className="modal">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
              exit={{ opacity: 0, transition: { delay: 0.1 } }}
              className="overlay"
            ></motion.div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1, transition: { duration: 0.3 } }}
              exit={{ scale: 0 }}
              className="modal-content"
            >
              <div className="button-close-modal" onClick={toggleModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#ffffff80"
                >
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                </svg>
              </div>
              <form className="info" onSubmit={updateUser}>
                <h1 className="title">Modifier Vente/Location</h1>

                <div className="inputBox-Radio">
                  <span>Type :</span>
                  <div className="boxRadio">
                    {/* <div className="radioBox"> */}
                    <label htmlFor="color" className="radio">
                      <input
                        type="radio"
                        className="inputColorOne"
                        value='Location'
                        name="type"
                        checked={itemType === 'Location'}
                        onChange={onOptionChange}
                      /> :
                      Location
                      <span></span>
                    </label>
                    <label htmlFor="color" className="radio">
                    <input
                        type="radio"
                        className="inputColorOne"
                        value='Vente'
                        name="type"
                        checked={itemType === 'Vente'}
                        onChange={onOptionChange}
                      /> :
                      Vente
                      <span></span>
                    </label>
                  </div>
                </div>


                <div className="info">
                  <p>Titre : {item.titre}</p>
                </div>

                <div className="inputBox">
                  <input ref={addInputs} type="text" name="titre" id="titre" />
                  <span>Titre :</span>
                </div>


                <div className="info">
                  <p>Prix : {item.prix}</p>
                </div>
                <div className="inputBox">
                  <input
                    ref={addInputs}
                    type="number"
                    name="prix"
                    id="prix"
                    min="0"
                  />
                  <span>Prix :</span>
                </div>
                <div className="info img">
                  <p>Image : </p><img src={item.image} alt="old pics" />
                </div>

                <div className="inputBox">
                  <input
                    type="file"
                    name="image"
                    id="prix"
                    onChange={handleImage}
                  />
                  <span>Photos :</span>
                </div>

                <div className="info">
                  <p>Description : {item.description}</p>
                </div>
                <div className="inputBox">
                  <textarea
                    ref={addInputs}
                    type="text"
                    name="prix"
                    id="prix"
                    min="0"
                  />
                  <span>Description :</span>
                </div>
                <div className="validation"></div>
                <div>
                  <button className="button" type="submit">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {
                      isLoading ? 
                      <div class="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div> :
                      <p>Update</p>
                    }
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}

export default EditModal;
