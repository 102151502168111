import React, { useEffect, useState } from "react";
import Card from "../../../components/Card/Card";
import { db } from "../../../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import Modal from "../../../components/Modal/Modal";

function PrivateDashBoard() {
  const [items, setItems] = useState([]);
  const itemsCollectionRef = collection(db, "items");

  const getItems = async () => {
    const data = await getDocs(itemsCollectionRef);
    setItems(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    return items;
  };

  useEffect(() => {
    getItems();
      console.log("items", items);
      console.log("items", items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="container-title">
        <h1>DashBoard</h1>
        <Modal
          itemsCollectionRef={itemsCollectionRef}
          setItems={setItems}
          items={items}
        />
      </div>
      {items.map((item) => {
        return (
          <Card item={item} key={item.id} setItems={setItems} items={items} />
        );
      })}
    </div>
  );
}

export default PrivateDashBoard;
