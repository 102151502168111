import { deleteDoc, doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../firebase.config";
import EditModal from "../Modal/EditModal";
import "./Card.css";
import Logo from "../../assets/logo/Studio_Vador_logo.png";
import { deleteObject, ref } from "firebase/storage";

function Card(props) {
  const navigate = useNavigate();

  const deleteItem = async (id) => {
    if (window.confirm("T'es sûre que tu veux supprimer cet élément ?")) {
      try {
        const itemDoc = doc(db, "items", id);
        const docSnap = await getDoc(itemDoc);
        console.log(docSnap.data().imageName);
        const imageRef = ref(storage, "images/" + docSnap.data().imageName);
        deleteObject(imageRef);
        deleteDoc(itemDoc);
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="card-container">
      <div className="card-info">
        <div className="div1">
          {props.item.image ? (
            <img src={props.item.image} alt="vador item" />
          ) : (
            <img src={Logo} alt="vador logo" />
          )}
        </div>
        <div className="parent">
          <div className="div1">
            <h3>
              {props.item.type} : {props.item.titre}
            </h3>
            <h4>Prix : {props.item.prix}€</h4>
          </div>
          <div className="div2">
            <p>Description : {props.item.description}</p>
          </div>
          <div className="div3">
            <EditModal id={props.item.id} />
            <button
              className="button"
              onClick={() => {
                deleteItem(props.item.id);
              }}
            >
              delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
